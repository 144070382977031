@import '../../../utils/colorConstants.scss';

.approved-status,
.active-status,
.signedonmobile-status,
.signed-status,
.nosignature-status {
  color: $WHITE_COLOR !important;
  background-color: $APPROVED_STATUS_COLOR !important;
}

.missing-status,
.archived-status,.notlocked-status {
  color: $WHITE_COLOR !important;
  background-color: $MISSING_STATUS_COLOR !important;
}
.readytosign-status {
  color: $WHITE_COLOR !important;
  background-color: $READY_TO_REVIEW_COLOR !important;
}

.reviewed-status {
  color: $WHITE_COLOR !important;
  background-color: $REVIEWED_STATUS_COLOR !important;
}


.pending-status {
  color: $LIGHT_BLACK !important;
  background-color: $PENDING_STATUS_COLOR !important;
}

.status-wrapper {
  padding: 4px 16px;
  border-radius: 16px;
  color: $LIGHT_BLACK;
  background-color: $PENDING_STATUS_COLOR;
  size: 12px;
  font-size: 12px;
  font-weight: 500;
  display: inline-block;
  width: 150px;
  text-align: center;
}
