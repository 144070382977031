.app-container {
  width: 100%;
  min-height: 100vh;
}

.children-container {
  width: 100%;
  min-height: 100vh;
}

@media only screen and (min-width: 1425px) {
  .MuiContainer-maxWidthLg {
    max-width: unset !important;
    padding: 0 100px !important;
  }
}
