$WHITE_COLOR: #ffffff;
$BLACK_COLOR: #000000;
$RED_COLOR: red;
$OPAQUE_BACKGROUND_COLOR: rgba(0, 0, 0, 0.7);
$LIGHT_GREEN_COLOR: #90c548;
$HOVER_LIGHT_GREEN_COLOR: #678f31;
$BRIGHT_GREY_COLOR: #3c404d;
$LIGHT_BLACK: #4a4a4a;
$GREY_BACKGROUND_COLOR: #eeeeee;
$TABLE_HEADER_COLOR: #4863c4;

$APPROVED_STATUS_COLOR: #90c548;
$MISSING_STATUS_COLOR: #e57676;
$REVIEWED_STATUS_COLOR: #e2c357;
$PENDING_STATUS_COLOR: #dcdcdc;
$LIGHT_SKY_BLUE_COLOR: #f8faff;
$LIGHT_NAVY_BLUE_COLOR: #e2e6f6;
$THEME_BLUE_COLOR: #3f51b5;
$SKELETON_BACKGROUND_COLOR: $LIGHT_SKY_BLUE_COLOR;
$READY_TO_REVIEW_COLOR: rgb(248, 163, 6);
$DUSTY_YELLOW: #fcface;
$DUSTY_GREEN: #effdec;
