@import '../../utils/colorConstants.scss';

.receipt-details-fields-container {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .details-field-container {
    margin-bottom: 20px;
  }

  .submit-button-container {
    margin: 20px 0 50px;
  }

  .receipt-details-fields-container .standard-basic {
    min-width: 228px;
  }

  .details-field-container .MuiFormControl-root {
    min-width: 228px;
  }

  .receipt-details-fields-container .MuiInput-underline.Mui-disabled:before {
    border-bottom-style: none;
  }
}
